<template>
    <modal ref="modalStock" titulo="Escanear código de barras" tamano="modal-lg" :cargado-modal="cargando" no-aceptar adicional="Actualizar" @adicional="agregar_stock">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0">
                    <div class="col-12 mb-4 px-0">
                        <el-select
                        v-model="id_producto"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Buscar producto"
                        :remote-method="remoteMethod"
                        class="w-100 br-20"
                        @change="get_detalle"
                        >
                            <el-option
                            v-for="item in opciones"
                            :key="item.id"
                            :label="`${item.nombre} - ${item.presentacion}`"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div v-show="!noDisponible && id_producto" class="row mx-0">
                    <div class="col-12 my-2  d-flex">
                        <img :src="producto.imagen" width="89" height="89" class="br-4 obj-cover" />
                        <div class="col">
                            <p class="f-15 text-general">{{ producto.nombre }}</p>
                            <p class="text-general2 mt-2">{{ producto.presentacion }} {{ producto.unidad }}</p>
                        </div>
                    </div>
                    <div class="col-4">
                        <p class="text-general pl-3 f-14">Cantidad en inventario</p>
                        <el-input v-model="producto.stock_disponible" disabled class="w-100" size="small" />
                    </div>
                    <div class="col-4">
                        <p class="text-general pl-3 f-14">Cantidad a comprar</p>
                        <el-input-number
                        v-model="model.cantidad"
                        size="small"
                        :min="minimo" :step="salto"
                        :step-strictly="entero"
                        :precision="presicion"
                        class="w-100"
                        />
                    </div>
                    <div class="col-4">
                        <p class="text-general pl-3 f-14">Valor Unitario</p>
                        <div class="d-middle">
                            <money v-model="model.valor" class="input-money" v-bind="money" />
                            <span class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ calcularSiglaActual(id_cedis) }}</span>
                        </div>
                    </div>
                    <div class="col-12 my-4">
                        <p class="text-general f-14 pl-3">Proveedor</p>
                        <el-select v-model="model.id_proveedor" size="small" filterable clearable class="w-100">
                            <el-option
                            v-for="item in proveedores"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col-12 mt-2">
                        <p class="text-general f-14 pl-3">Observación de la compra</p>
                        <el-input v-model="model.comentario" type="textarea" :rows="3" class="w-100" />
                    </div>
                    <div class="col-12 px-0 mt-4">
                        <div class="row mx-0">
                            <div class="col">
                                <p class="text-general f-15 f-500 mb-2">Último costo reportado</p>
                                <div class="row mx-0">
                                    <img :src="_.get(producto.ultimo_valor_costo,'creador.foto','')" width="54" height="64" class="border br-4 obj-cover" />
                                    <div class="col">
                                        <p class="text-general f-15">{{ _.get(producto.ultimo_valor_costo,'creador.nombre','') }}</p>
                                        <p class="text-general f-15">{{ formatearFecha(_.get(producto.ultimo_valor_costo,'created_at','')) }}</p>
                                        <p class="text-general f-15">{{ separadorNumero(_.get(producto.ultimo_valor_costo,'valor')) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <p class="text-general f-15 f-500 mb-2">Último valor de venta</p>
                                <div class="row mx-0">
                                    <img :src="_.get(producto.ultimo_valor_venta,'creador.foto','')" width="54" height="64" class="border br-4 obj-cover" />
                                    <div class="col">
                                        <p class="text-general f-15">{{ _.get(producto.ultimo_valor_venta,'creador.nombre','') }}</p>
                                        <p class="text-general f-15"> {{ formatearFecha(_.get(producto.ultimo_valor_venta,'created_at','')) }}</p>
                                        <p class="text-general f-15"> {{ separadorNumero(_.get(producto.ultimo_valor_venta,'valor')) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="noDisponible" class="row mx-0 justify-center my-3">
                    <div class="text-center">
                        <i class="icon-alert-triangle text-gris" style="font-size:50px;" />
                        <p class="text-general f-16 mt-2">Este producto no se vende <br /> en este CEDIS</p>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import {Money} from 'v-money'
import Productos from "~/services/productos/productos_stocks";
import ProductosStocks from '~/services/productos/productos_stocks'
import CedisProductos from '~/services/cedis/cedis_productos'

export default {
    components:{
        Money,
    },
    data(){
        return{
            noDisponible:false,
            cargando:false,
            id_producto: null,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },

            minimo:0,
            salto:0,
            entero:false,
            presicion:0,

            opciones:[],
            proveedores:[],
            producto:{},
            model:{
                valor: 0,
                cantidad: null,
                id_proveedor: null,
                comentario:''
            },
        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
    },
    methods: {
        toggle(){
            this.limpiar()
            this.money.precision = this.getDecimales(this.id_cedis)
            this.$refs.modalStock.toggle();
        },
        async remoteMethod(query){
            try {
                if(query.length > 2){
                    const busqueda = async() => {
                        let params = {
                            id_cedis: this.id_cedis,
                            query
                        }

                        const {data} = await Productos.buscadorProductos(params)
                        this.opciones = data.productos
                    }
                    await this.delay(busqueda)
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async get_detalle(id_producto){
            try {

                let find = this.opciones.find(o=>o.id == id_producto)
                if(find.id_cp === null){
                    this.noDisponible = true
                    return
                }else{
                    this.noDisponible = false
                }

                this.listaProveedores()
                const params = {
                    id_cedis: this.id_cedis
                }
                const {data} = await  ProductosStocks.info_producto_stock(id_producto, params)

                this.producto = data.producto
                this.model.id_proveedor = this.producto.ultimo_valor_venta.id_proveedor
                this.minimo = parseFloat(this.producto.cantidad_minima)
                this.salto = parseFloat(this.producto.cantidad_minima)
                this.entero = this.producto.cantidad_tipo === 1
                this.presicion = this.producto.cantidad_tipo === 1 ? 0 : 2
                this.model.cantidad = parseFloat(this.producto.cantidad_minima)


            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        async agregar_stock(){
            try {
                if(this.noDisponible) return
                this.model.id_producto = this.id_producto
                this.model.id_cedis= this.id_cedis
                this.cargando = true
                const {data} = await  ProductosStocks.agregarStock(this.model)
                this.notificacion('Mensaje', 'Compra registrada', 'success')
                this.$refs.modalStock.toggle()

            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        async listaProveedores(){
            try {
                const id_cedis = this.id_cedis

                const {data} = await CedisProductos.listaProveedores({id_cedis})
                this.proveedores = data.proveedores
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.noDisponible = false
            this.cargando = false
            this.id_producto =  null

            this.minimo = 0
            this.salto = 0
            this.entero = false
            this.presicion = 0

            this.opciones = []
            this.proveedores = []
            this.producto = {}
            this.model = {
                valor: 0,
                cantidad: null,
                id_proveedor: null,
                comentario:''
            }
        },
    }
}
</script>
